define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/memberCards/list/item',
  'modules/shop.cash-register-retail/components/toaster',
  'modules/common/components/locale',
], (
  $, _, Backbone, Template, Toaster, Locale,
) => Backbone.Marionette.LayoutView.extend({

  template: Template,

  tagName: 'tr',

  onShow() {
    const self = this;
    this.$el.on('click', () => {
      self.triggerMethod('child:clicked', self.model);
      self.$el.addClass('add-blink');
      setTimeout(() => {
        self.$el.removeClass('add-blink');
      }, 500);
      Toaster.info(Locale.translate('customer_selected'));
    });
  },

}));
