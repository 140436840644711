define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/newsLetters/list/layout.hbs',

  './list',

  'modules/shop.common/collections/upx/Newsletter',

  'modules/common/components/locale',

  'modules/admin/behaviors/loader',
], (
  $, _, Backbone, Template,
  ListView,
  NewsletterCollection,
  Locale,
  Loader,
) => Backbone.Marionette.LayoutView.extend({

  template: Template,

  className: 'news-letters',

  regions: {
    items: '[data-region="items"]',
  },

  events: {
    'click [data-action="more"]': 'moreClicked',
    'touchend [data-action="more"]': 'moreClicked',
  },

  ui: {
    more: '[data-ui="more"]',
  },

  behaviors: {
    Loader: {
      behaviorClass: Loader,
    },
  },

  initialize() {
    this.collection = new NewsletterCollection();
  },

  moreClicked: _.debounce(function () {
    if (this.collection.canFetchNext()) {
      const def = this.loader.startLoader('more');
      const self = this;
      this.collection.fetchNext()
        .always(() => {
          self.checkMore();
          def.resolve();
        });
    }
  }, 500),

  checkMore() {
    if (!this.isDestroyed) {
      if (this.collection.canFetchNext()) {
        this.ui.more.show();
      } else {
        this.ui.more.hide();
      }
    }
  },

  updateCollection() {
    const def = this.loader.startLoader('main');

    const params = {
      params: {
        start: 0,
        limit: 20,
        sort: [{
          name: 'date_published',
          dir: 'desc',
        }],
      },
    };

    const self = this;
    this.collection.fetch(params)
      .always(() => {
        self.checkMore();
        def.resolve();
      });
  },

  onShow() {
    this.updateCollection();
    this.collection.on('layout:swap', this.layoutSwap, this);
  },

  layoutSwap(swap, data) {
    this.triggerMethod('layout:swap', swap, data);
  },

  onDestroy() {
    this.collection.off('layout:swap', this.layoutSwap, this);
  },

  onRender() {
    this.renderItems();
  },

  renderItems() {
    const region = this.getRegion('items');
    const view = new ListView({
      collection: this.collection,
    });
    region.show(view);
  },

}));
