define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/orders/number',
  'modules/shop.cash-register-retail/behaviors/cellWithKeyboard',
  'modules/common/components/currency',
], (
  $, _, Backbone, Template, WithKeyboardBehaviour, Currency,
) => Backbone.Marionette.ItemView.extend({

  behaviors: {
    WithKeyboardBehaviour: {
      behaviorClass: WithKeyboardBehaviour,
      preventRender: true,
    },
  },

  ui: {
    input: 'input',
  },

  template: Template,

  initialize() {
    this.keyboardMode = this.keyboardModel.MODE_ORDER_SEARCH;
    this.firstInput = true;

    this.attributeName = 'value';
    if ('attributeName' in this.options) {
      this.attributeName = this.options.attributeName;
    }

    if (!('model' in this.options)) {
      this.model = new Backbone.Model({ value: '' });
    }

    if ('startValue' in this.options) {
      this.model.set(this.attributeName, Currency.toCurrency(this.options.startValue));
    }
    this.currentValue = this.model.get(this.attributeName);

    const self = this;

    this.model.on(`change:${this.attributeName}`, (model, value) => {
      self.currentValue = value;
      if (!self.isDestroyed) {
        self.render();
      }
    });
  },

  getValue() {
    return this.model.get(this.attributeName);
  },

  onKeyPress(key) {
    const current = this.currentValue || '';
    this.currentValue = current + key;
  },

  onBackspacePress() {
    this.currentValue = this.removeLastCharacterFromString(this.currentValue);
  },

  onRender() {
    this.ui.input.val(this.currentValue);
  },

  onConfirmation() {
    this.model.set(this.attributeName, this.currentValue);
  },

  onCancel() {
    this.currentValue = this.model.get(this.attributeName);
    if (!this.isDestroyed) {
      this.render();
    }
  },

  onShow() {
    this.checkSearchInterval = setInterval(() => {
      // select this as mode when no mode is set
      if (!this.keyboardModel.hasMode()) {
        this.keyboardModel.orderNumberMode(this.cid);
      }
    }, 200);
  },

  onDestroy() {
    clearInterval(this.checkSearchInterval);
  },
}));
