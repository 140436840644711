define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/newsLetters/list/list.hbs',
  './listItem',

], (
  $, _, Backbone, Template, ItemView,
) => Backbone.Marionette.CompositeView.extend({

  template: Template,

  childView: ItemView,

  childViewContainer: 'tbody',

}));
