define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/views/onlineFoodOrder/overview/item.js',
], (
  $, _, Backbone, ItemView,
) => Backbone.Marionette.CollectionView.extend({

  className: 'online-food-collection',

  childView: ItemView,

  initialize() {
    this.listenTo(this.collection, 'collectionView:render', _.debounce(() => this.render(), 5));
  },
}));
