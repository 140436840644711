define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/orders/overview/item.hbs',
  'modules/shop.cash-register-retail/components/order',
], (
  $, _, Backbone, Template, OrderComponent,
) => Backbone.Marionette.LayoutView.extend({

  template: Template,

  tagName: 'tr',

  ui: {
    icon: '[data-ui="icon"]',
  },

  events: {
    click: 'elementClicked',
  },

  modelEvents: {
    renderIcon: 'onRender',
  },

  elementClicked() {
    this.triggerMethod('child:clicked', this.model);
  },

  onRender() {
    if (!this.model.get('is_paid')) {
      // not paid check if it`s still synching
      this.renderIfSynching();
    } else {
      this.renderIcon(true);
    }
  },

  async renderIfSynching() {
    const sync = await OrderComponent.isOrderSynchronizingToBackend(this.model.get('id'));
    if (sync) {
      this.renderIcon();
    } else {
      this.renderIcon(false);
    }
  },

  renderIcon(status) {
    if (!this.isDestroyed) {
      const icon = $('<i class="fas fa-2x "></i>');
      if (typeof status === 'boolean') {
        if (status) {
          icon.addClass('fa-check text-success');
        } else {
          icon.addClass('fa-times text-danger');
        }
      } else {
        icon.addClass('fa-sync text-warning');
      }
      this.ui.icon.empty();
      this.ui.icon.append(icon);
    }
  },

  serializeData() {
    const data = this.model.toJSON();
    if (this.model.has('created_by.name') && this.model.has('device_id')) {
      const name = this.model.get('created_by.name') || '';
      const regex = /(?<letter>[\w])\w+\W*/gm;
      data.initials = name.replace(regex, '$1');
    }

    return data;
  },
}));
