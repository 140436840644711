define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/onlineFoodOrder/overview/item.hbs',
  'modules/shop.cash-register-retail/views/popups/onlineFoodOrder/actionPopup',
  'modules/shop.cash-register-retail/components/onlineFoodOrder.js',

], (
  $, _, Backbone, Template,
  FoodOrderConfirmPopup, OnlineFoodOrderComponent,
) => Backbone.Marionette.ItemView.extend({

  template: Template,
  className: 'btn designer-box rounded online-food-item',

  events: {
    click: 'onClick',
  },

  modelEvents: {
    change: 'onModelChange',
    'change:status': 'onModelCollectionChange',
  },

  onClick() {
    const view = new FoodOrderConfirmPopup({
      model: this.model,
    });
    view.open();
  },

  onModelChange() {
    this.render();
  },
  onModelCollectionChange() {
    this.model.collection.trigger('collectionView:render');
  },
  serializeData() {
    return OnlineFoodOrderComponent.getSitedishDataFromModel(this.model);
  },
}));
