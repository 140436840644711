define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/orders/layout',

  'modules/shop.cash-register-retail/views/popups/orderDetailsPopup',
  'modules/shop.cash-register-retail/views/popups/confirmPopup',
  'modules/shop.cash-register-retail/views/popups/messagePopup',

  './date',
  './amount',
  './number',
  './product',
  './overview/collection',
  'modules/shop.cash-register-retail/views/keypads/main',

  'modules/shop.cash-register-retail/models/keyboard',
  'upx.modules/ShopModule/models/Order',

  'upx.modules/ShopModule/collections/Order',
  'modules/shop.cash-register-retail/collections/currentOrderItem',
  'upx.modules/ShopModule/collections/OrderItem',

  'modules/shop.cash-register-retail/collections/TaxRate',

  'modules/admin/behaviors/loader',
  'modules/shop.cash-register-retail/models/selectedCustomer',

  'modules/shop.cash-register-retail/components/refundOrder',
  'modules/common/components/locale',
  'modules/shop.cash-register-retail/components/order',
  'modules/shop.cash-register-retail/components/printing',
  'modules/upx/components/upx',
  'modules/shop.cash-register-retail/components/toaster',
  'modules/common/components/historyBreadcrumb',

  'modules/shop.cash-register-retail/models/settings/receiptPrinter',
], (
  $, _, Backbone, Template,
  ReturnProductsPopupView, ConfirmPopupView, MessagePopupView,
  DateView, AmountView, NumberView, ProductView, OverviewCollectionView, KeypadView,
  KeyboardModel, OrderModel,
  OrderCollection, CurrentOrderItemCollection, OrderItemCollection,
  TaxRateCollection,
  Loader, SelectedCustomerModel,
  RefundOrder, Locale, Order, Printing, Upx, Toaster, HistoryBreadcrumb,
  ReceiptPrinterModel,
) => Backbone.Marionette.LayoutView.extend({

  template: Template,

  className: 'order-overview',

  modelDefaults: {
    productSearch: null,
    dateFrom: null,
    dateTo: null,
    amountFrom: null,
    amountTo: null,
    number: null,
    shop_product_id: null,
  },

  regions: {
    date: '[data-region="date"]',
    amount: '[data-region="amount"]',
    number: '[data-region="number"]',
    product: '[data-region="product"]',
    overview: '[data-region="overview"]',
    keypad: '[data-region="keypad"]',
    popup: '[data-region="popup"]',
  },

  modelEvents: {
    change: 'modelChanged',
  },

  childEvents: {
    'loader:start': function (view, def, name) { this.triggerMethod('loader:start', def, name); },
  },

  events: {
    'click [data-action="last"]': 'lastClicked',
    'click [data-action="reset"]': 'resetClicked',
    'click [data-action="print-returns"]': 'printReturnsClicked',
  },

  behaviors: {
    Loader: {
      behaviorClass: Loader,
    },
  },
  initialize(options = {}) {
    this.openOrderId = options.openOrderId;
    this.model = new Backbone.Model(this.modelDefaults);
    // Collections used to search
    this.searchCollection = new OrderCollection();
    this.serialCollection = new OrderCollection();

    // Collection used to list items
    this.collection = new OrderCollection();
    this.collection.comparator = function (order) {
      return -order.get('id'); // newest orders on top
    };
    this.collection.fetchNext = () => this.fetchNextCollection();
    this.collection.canFetchNext = () => this.canFetchNextCollection();

    const self = this;
    this.on('loader:start', (def, name) => {
      self.loader.showLoader(name);
      def.always(() => {
        self.loader.hideLoader(name);
      });
    });
  },

  serializeData() {
    return {
      has_receipt_printer: ReceiptPrinterModel.isWantedType(),
    };
  },

  lastClicked() {
    const orderModel = Order.getPreviousOrder();
    if (orderModel) {
      this.openReturnProductsPopup(orderModel);
    } else {
      const view = new MessagePopupView();
      view.open(Locale.translate('no_previous_order_has_been_found_dot'));
    }
  },

  printReturnsClicked() {
    const def = this.loader.startLoader();

    const orderItemCollection = new OrderItemCollection();
    const orderItemParams = {
      start: 0,
      limit: 0,
      sort: [
        {
          name: 'order_id',
          dir: 'asc',
        },
        {
          name: 'rownb',
          dir: 'asc',
        },
      ],
      filters: [{
        name: 'quantity__<',
        val: 0,
      }, {
        name: 'order/date_created__ondate',
        val: 'now()',
      }, {
        name: 'order/status__!=',
        val: 'cancelled',
      }],
    };

    orderItemCollection.fetch({ params: orderItemParams })
      .then(() => {
        Printing.printDailyReturns(orderItemCollection)
          .then(def.resolve, def.reject);
      }, def.reject);
  },

  resetClicked() {
    Backbone.history.navigate('orders/reload', { trigger: true });
  },

  fetchNextCollection() {
    const callStack = [];
    const def = new $.Deferred();
    const hasSerial = this.model.has('productSearch');
    const hasProduct = this.model.has('shop_product_id');

    this.triggerMethod('loader:start', def, 'overview');

    if (!hasProduct && !hasSerial) {
      // Call > wrap deferred into a resolve function > add to callstack
      callStack.push(
        this.fetchAndAdd(
          this.searchCollection.fetchNext(),
        ),
      );

      // Call > wrap deferred into a resolve function > add to callstack
      callStack.push(
        this.fetchAndAdd(
          this.serialCollection.fetchNext(),
        ),
      );
    }

    if (hasProduct) {
      // Call > wrap deferred into a resolve function > add to callstack
      callStack.push(
        this.fetchAndAdd(
          this.searchCollection.fetchNext(),
        ),
      );
    }

    if (hasSerial) {
      // Call > wrap deferred into a resolve function > add to callstack
      callStack.push(
        this.fetchAndAdd(
          this.serialCollection.fetchNext(),
        ),
      );
    }

    if (callStack.length <= 0) {
      return def.resolve();
    }
    $.when.apply($, callStack).then(
      () => {
        this.collection.sort();
        def.resolve();
      },
      def.reject,
    );

    return def;
  },

  canFetchNextCollection() {
    let canFetchNext = false;
    const hasSerial = this.model.has('productSearch');
    const hasProduct = this.model.has('shop_product_id');

    if (!hasProduct && !hasSerial) {
      canFetchNext = this.searchCollection.canFetchNext() || this.serialCollection.canFetchNext();
    }

    if (hasProduct) {
      canFetchNext = this.searchCollection.canFetchNext();
    }

    if (hasSerial) {
      canFetchNext = this.serialCollection.canFetchNext();
    }

    return canFetchNext;
  },

  fetchCollection() {
    const def = new $.Deferred();
    const m = this.model;

    this.resetCollection();

    const hasProduct = m.has('shop_product_id');
    const hasSerial = m.has('productSearch');

    const searchFilters = this.getFilters();
    const serialFilters = searchFilters.concat([]);

    const callStack = [];

    if (!hasProduct && !hasSerial) {
      // Call > wrap deferred into a resolve function > add to callstack
      callStack.push(
        this.fetchAndAdd(
          this.searchCollection.fetch({ params: this.getParameters(searchFilters) }),
        ),
      );

      // Call > wrap deferred into a resolve function > add to callstack
      callStack.push(
        this.fetchAndAdd(
          this.serialCollection.fetch({ params: this.getParameters(serialFilters) }),
        ),
      );
    }

    if (hasProduct) {
      // add product filter
      searchFilters.push({
        name: 'shop_product_id__=',
        val: m.get('shop_product_id'),
      });

      // Call > wrap deferred into a resolve function > add to callstack
      callStack.push(
        this.fetchAndAdd(
          this.searchCollection.fetch({ params: this.getParameters(searchFilters) }),
        ),
      );
    }

    if (hasSerial) {
      // add serial filter
      serialFilters.push({
        name: 'product_serial_no__used',
        val: m.get('productSearch'),
      });

      // Call > wrap deferred into a resolve function > add to callstack
      callStack.push(
        this.fetchAndAdd(
          this.serialCollection.fetch({ params: this.getParameters(serialFilters) }),
        ),
      );
    }

    if (callStack.length <= 0) {
      return def.resolve();
    }
    $.when.apply($, callStack).then(
      () => {
        this.collection.sort();
        def.resolve();
      },
      def.reject,
    );

    return def;
  },

  fetchAndAdd(fetchDef) {
    fetchDef.then((resp) => {
      this.collection.add(resp.data);
    }, (resp) => {
      if ('error' in resp) {
        Toaster.error(resp.error);
      }
    });
    return fetchDef;
  },

  resetCollection() {
    this.searchCollection.reset();
    this.serialCollection.reset();
    this.collection.reset();
  },

  getParameters(filters) {
    return {
      start: 0,
      limit: 20,
      sort: [{
        name: 'date_created',
        dir: 'desc',
      }],
      filters,
    };
  },

  modelChanged() {
    const def = new $.Deferred();

    def.then(() => {
      if (this.model.has('number') && this.collection.length === 1) {
        this.openReturnProductsPopup(
          this.collection.first(),
        );
      }
    }, (resp) => {
      if ('error' in resp) {
        Toaster.error(resp.error);
      }
    });

    this.triggerMethod('loader:start', def, 'overview');

    this.fetchCollection()
      .then(def.resolve, def.reject);
  },

  getFilters() {
    const m = this.model;
    const filters = [{
      name: 'is_quote__=',
      val: '0',
    }, {
      name: 'is_contract__=',
      val: '0',
    }, {
      name: 'is_request__=',
      val: '0',
    }, {
      name: 'status__not_in_list',
      multi_val: ['cancelled'],
    }];

    /*
             * Amount
             */
    if (m.has('amountFrom')) {
      filters.push({
        name: 'value_wt__>=',
        val: m.get('amountFrom'),
      });
    }
    if (m.has('amountTo')) {
      filters.push({
        name: 'value_wt__<=',
        val: m.get('amountTo'),
      });
    }

    /*
             *  Dates
             */
    if (m.has('dateFrom')) {
      filters.push({
        name: 'date_created__>',
        val: m.get('dateFrom').toISOString(),
      });
    }
    if (m.has('dateTo')) {
      const d = new Date(m.get('dateTo').toISOString());
      d.setDate(d.getDate() + 1); // next day, because it's included
      filters.push({
        name: 'date_created__<',
        val: d.toISOString(),
      });
    }

    /*
             * Number
             */
    if (m.has('number')) {
      filters.push({
        name: 'search__word_or_item',
        val: m.get('number'),
      });
    }

    return filters;
  },

  onRender() {
    this.renderDate();
    this.renderAmount();
    this.renderNumber();
    this.renderProduct();
    this.renderOverview();
    this.renderKeypad();

    if (this.openOrderId) {
      const order = new OrderModel({ id: this.openOrderId });
      this.openReturnProductsPopup(order);
    }
  },

  renderDate() {
    const region = this.getRegion('date');
    const view = new DateView({
      model: this.model,
    });
    region.show(view);
  },

  renderAmount() {
    const region = this.getRegion('amount');
    const view = new AmountView({
      model: this.model,
    });
    region.show(view);
  },

  renderNumber() {
    const region = this.getRegion('number');
    this.numberView = new NumberView({
      model: this.model,
      attributeName: 'number',
    });
    region.show(this.numberView);
  },

  renderProduct() {
    const region = this.getRegion('product');
    const view = new ProductView({
      model: this.model,
      parentView: this,
    });
    region.show(view);
  },

  renderOverview() {
    const self = this;
    const region = this.getRegion('overview');
    const view = new OverviewCollectionView({
      collection: this.collection,
    });

    region.show(view);

    view.on('receipt:selected', (model) => {
      self.openReturnProductsPopup(model);
    });
  },

  renderKeypad() {
    const region = this.getRegion('keypad');
    const view = new KeypadView();
    region.show(view);
  },

  onShow() {
    KeyboardModel.setViewWithMode(this.numberView.cid, KeyboardModel.MODE_ORDER_SEARCH);
    this.modelChanged();
  },

  /**
   * @param currentOrderModel
   */
  openReturnProductsPopup(currentOrderModel) {
    const def = this.loader.startLoader();
    def.fail((resp) => Toaster.error(resp.error));

    const orderParams = {
      id: currentOrderModel.get('id'),
      options: {
        lang: Locale.getLocale(),
        format_subitems: true,
        get_order_vars: true,
      },
    };

    $.when(
      Upx.call('ShopModule', 'getOrder', orderParams),
      TaxRateCollection.load(),
    ).then((orderData) => {
      currentOrderModel.set(orderData);

      def.resolve();

      const urlHash = `orders/${currentOrderModel.get('id')}`;
      HistoryBreadcrumb.replaceCurrent(urlHash);

      const refundDef = new $.Deferred();
      const view = new ReturnProductsPopupView({
        model: currentOrderModel,
        refundDef,
      });
      view.open();
      view.on('popup:closed', () => {
        const current = Backbone.history.getHash();
        if (current === urlHash) {
          HistoryBreadcrumb.replaceCurrent('orders');
        }
      });

      refundDef.then((refundCollection) => {
        // Check if there is anything to refund
        if (refundCollection.length > 0) {
          // Refund function
          const refund = () => {
            CurrentOrderItemCollection.clear();
            refundCollection.each((model) => {
              model.set('currency_iso3', currentOrderModel.get('currency_iso3'));

              const orderItemModel = CurrentOrderItemCollection.addProductByOrderItemModel({
                orderItemModel: model,
                forceNew: true,
              });
              orderItemModel.getCalculatedSubItems();
              orderItemModel.save();
            });
            RefundOrder.set(currentOrderModel);

            // select customer if none selected
            if (!currentOrderModel.get('is_anonymous') && !SelectedCustomerModel.get('id')) {
              SelectedCustomerModel.selectByRelationDataId(
                currentOrderModel.get('relation_data_id'),
              );
            }
            Backbone.history.navigate('checkout', { trigger: true });
          };

          // Check if there are items in the current order
          if (CurrentOrderItemCollection.length > 0) {
            const confirmView = new ConfirmPopupView();
            const title = Locale.translate('creating_a_refund_will_clear_the_current_items_in_your_order');
            confirmView.open(title)
              .then(() => { refund(); });
          }
          // else just refund
          else {
            refund();
          }
        }
      }, def.reject);
    }, def.reject);
  },

}));
