define([
  'jquery',
  'underscore',
  'backbone',
  'modules/common/views/swappableWithLoading',

  './list/layout',
  './details/layout',
], (
  $, _, Backbone, Swappable,
  ListView, DetailsView,
) => Swappable.extend({

  initialize(options) {
    Swappable.prototype.initialize.call(this, options);

    this.setMainView(ListView);
    this.setView('details', DetailsView);
  },

}));
