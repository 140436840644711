define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/openOrders/list/layout.hbs',

  './list',

  'modules/shop.cash-register-retail/collections/upx/Order',
  'modules/shop.cash-register-retail/models/upx/Order',
  'modules/shop.cash-register-retail/models/settings/receiptPrinter',
  'modules/shop.cash-register-retail/components/openOrder.js',

  'modules/common/components/locale',

  'modules/admin/behaviors/loader',
], (
  $, _, Backbone, Template,
  ListView,
  OrderCollection, OrderModel, ReceiptPrinter, OpenOrder,
  Locale,
  Loader,
) => Backbone.Marionette.LayoutView.extend({

  template: Template,

  className: 'open-orders',

  regions: {
    items: '[data-region="items"]',
  },

  events: {
    'change [data-action="search"]': 'searchChanged',
    'keyup [data-action="search"]': 'searchChanged',

    'click [data-action="more"]': 'moreClicked',
    'touchend [data-action="more"]': 'moreClicked',

    'click [data-action="clear"]': 'clearClicked',
    'touchend [data-action="clear"]': 'clearClicked',
  },

  ui: {
    search: '[data-ui="search"]',
    more: '[data-ui="more"]',
  },

  behaviors: {
    Loader: {
      behaviorClass: Loader,
    },
  },

  initialize({ orderId }) {
    this.collection = new OrderCollection();
    this.orderId = orderId;
  },

  searchChanged: _.debounce(function () {
    this.updateCollection();
  }, 500),

  moreClicked: _.debounce(function () {
    if (this.collection.canFetchNext()) {
      const def = this.loader.startLoader('more');
      const self = this;
      this.collection.fetchNext()
        .always(() => {
          self.checkMore();
          def.resolve();
        });
    }
  }, 500),

  openOrder() {
    const model = new OrderModel({ id: this.orderId });
    const def = this.loader.startLoader('main');
    OpenOrder
      .detailsPopup({
        model,
      })
      .then(def.resolve, def.reject);
  },

  clearClicked() {
    this.ui.search.val('');
    this.ui.search.trigger('keyup');
    this.ui.search.trigger('change');
    // Set the focus to the search view and popup the keyboard by `clicking` on it
    this.ui.search.focus();
    this.ui.search.click();
  },

  checkMore() {
    if (!this.isDestroyed) {
      if (this.collection.canFetchNext()) {
        this.ui.more.show();
      } else {
        this.ui.more.hide();
      }
    }
  },

  updateCollection() {
    const def = this.loader.startLoader('main');
    const filters = [
      {
        name: 'is_contract__=',
        val: '0',
      }, {
        name: 'is_delivered__=',
        val: '0',
      }, {
        name: 'status__not_in_list',
        multi_val: [
          'cancelled',
          'complete',
          'on_hold',
          'refunded',
          'concept',
        ],
      }];

    const value = this.ui.search.val();
    const query = value.trim();
    if (query.length > 0) {
      filters.push({
        name: 'search__word',
        val: query,
      });
    }

    const params = {
      params: {
        start: 0,
        limit: 20,
        sort: [{
          name: 'date_purchased',
          dir: 'desc',
        }],
        filters,
      },
    };

    const self = this;
    this.collection.fetch(params)
      .always(() => {
        self.checkMore();
        def.resolve();
      });
  },

  onShow() {
    this.updateCollection();
    // Set the focus to the search view and popup the keyboard by `clicking` on it
    this.ui.search.focus();
    this.ui.search.click();

    this.collection.on('list:reload', this.searchChanged, this);
  },

  onDestroy() {
    this.collection.off('list:reload', this.searchChanged, this);
  },
  onRender() {
    this.renderItems();

    if (this.orderId) {
      setTimeout(() => this.openOrder());
    }
  },

  renderItems() {
    const region = this.getRegion('items');
    const view = new ListView({
      collection: this.collection,
      loader: this.loader,
    });
    region.show(view);
  },

}));
