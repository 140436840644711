define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/orders/amount',

  'modules/common/components/locale',
  'modules/common/components/currency',

  'modules/shop.cash-register-retail/models/upx/DefaultShopConfiguration',

  'modules/shop.cash-register-retail/views/popups/fromToAmountPopup',
], (
  $, _, Backbone, Template,
  Locale, Currency,
  DefaultShopConfigurationModel,
  FromToAmountPopupPopup,
) => Backbone.Marionette.LayoutView.extend({

  template: Template,

  events: {
    click: 'elementClicked',
  },

  modelEvents: {
    'change:amountFrom': 'amountChanged',
    'change:amountTo': 'amountChanged',
  },

  regions: {
    popup: '[data-region=popup]',
  },

  elementClicked() {
    const view = new FromToAmountPopupPopup();
    const def = new $.Deferred();
    view.open(def);

    const self = this;
    def.then((data) => {
      self.model.set({
        amountFrom: data.from,
        amountTo: data.to,
      });
    });
  },

  amountChanged() {
    !this.isDestroyed ? this.render() : '';
  },

  getDisplayAmount() {
    const from = this.model.get('amountFrom');
    const to = this.model.get('amountTo');
    const iso3 = DefaultShopConfigurationModel.getCurrencyIso3();

    // If both are set
    if (from && to) {
      return `${from
      } / ${
        to}`;
    } if (from && !to) {
      return from;
    }
    // if non are set

    return Locale.translate('amount_from_till');
  },

  serializeData() {
    return {
      displayAmount: this.getDisplayAmount(),
    };
  },

}));
