define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/openOrders/list/listItem.hbs',

  'modules/shop.cash-register-retail/components/printing',
  'modules/shop.cash-register-retail/views/popups/messagePopup',

  'modules/shop.cash-register-retail/models/settings/receiptPrinter',

  'modules/shop.cash-register-retail/components/shippingMethod.js',

  'modules/shop.cash-register-retail/collections/routeCache',
  'modules/common/components/historyBreadcrumb',

  'modules/shop.cash-register-retail/components/openOrder.js',
], (
  $, _, Backbone, Template,
  PrintingComponent, MessagePopup,
  ReceiptPrinter,
  ShippingMethod,
  RouteCache, HistoryBreadcrumb,
  OpenOrder,
) => Backbone.Marionette.LayoutView.extend({

  template: Template,

  tagName: 'tr',

  events: {
    'click [data-action="pickup"]': 'pickupClicked',
    'click [data-action="details"]': 'detailsClicked',
    'click [data-action="print"]': 'printClicked',
    'click [data-action="pay"]': 'payClicked',
  },

  regions: {
    popup: '[data-region="popup"]',
  },

  ui: {
    print: '[data-ui="print"]',
  },

  initialize(options) {
    this.loader = options.loader;
  },

  pickupClicked() {
    const def = this.loader.startLoader('main');
    OpenOrder
      .pickupPopup({
        model: this.model,
      })
      .then(def.resolve, def.reject);
  },

  payClicked() {
    const orderId = this.model.get('id');
    const cacheId = RouteCache.createCache({
      orderId,
      backRoute: `open-orders/order/${orderId}`,
    });
    HistoryBreadcrumb.goto(`payment-screen/order/${cacheId}`);
  },

  detailsClicked() {
    const def = this.loader.startLoader('main');
    OpenOrder
      .detailsPopup({
        model: this.model,
      })
      .then(def.resolve, def.reject);
  },

  showError(error) {
    const view = new MessagePopup();
    view.open(error);
  },

  printClicked(e) {
    // Additional check if the button is disabled or not.
    const el = e.currentTarget;
    if (el.disabled) { return; }

    const def = this.loader.startLoader('print');
    this.model.collection.trigger('lock:print'); // Locks printing

    // Main deferred;
    def.then(() => {
      this.model.collection.trigger('unlock:print');
    }, (response) => {
      this.showError(response);
      this.model.collection.trigger('unlock:print');
    });

    this.model.loadOrderItems()
      .then(() => {
        this.model.loadShippedOrderItemSerials().then(
          () => {
            PrintingComponent.printOrderPackingSlip(this.model)
              .then(def.resolve, def.reject);
          },
          def.reject,
        );
      }, def.reject);
  },

  onShow() {
    this.model.collection.on('lock:print', this.lockPrint, this);
    this.model.collection.on('unlock:print', this.unlockPrint, this);
  },

  onDestroy() {
    this.model.collection.off('lock:print', this.lockPrint, this);
    this.model.collection.off('unlock:print', this.unlockPrint, this);
  },

  lockPrint() {
    this.ui.print.attr('disabled', true);
  },

  unlockPrint() {
    this.ui.print.attr('disabled', false);
  },

  serializeData() {
    const data = this.model.toJSON();
    data.showPackbonPrint = ReceiptPrinter.isWantedType() && !data.is_shipped;

    data.shipping = {
      name: ShippingMethod.getNameForOrder(this.model),
      icon: ShippingMethod.getIconForOrder(this.model),
    };
    return data;
  },

}));
