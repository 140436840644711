define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/orders/overview/empty',
], (
  $, _, Backbone, Template,
) => Backbone.Marionette.LayoutView.extend({

  template: Template,

  tagName: 'tr',

}));
