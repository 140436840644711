define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/newsLetters/details/layout.hbs',

  'modules/admin/behaviors/loader',
  'modules/shop.cash-register-retail/models/notifications',
], (
  $, _, Backbone, Template,
  Loader,
  NotificationsModel,
) => Backbone.Marionette.ItemView.extend({

  template: Template,

  className: 'news-letters',

  ui: {
    iframe: '[data-ui="iframe"]',
  },

  behaviors: {
    Loader: {
      behaviorClass: Loader,
    },
  },

  events: {
    'click [data-action="previous"]': 'previousClicked',
    'click [data-action="next"]': 'nextClicked',
  },

  previousClicked() {
    const col = this.model.collection;
    const mod = this.model;

    const index = col.indexOf(mod);
    let previousModel = col.at(index - 1);

    if (previousModel) {
      this.triggerMethod('layout:swap', 'details', { model: previousModel });
    } else if (col.canFetchPrevious()) {
      const def = this.loader.startLoader();
      const self = this;
      col.fetchPrevious()
        .then(() => {
          previousModel = col.at(index - 1);
          if (previousModel) {
            self.triggerMethod('layout:swap', 'details', { model: previousModel });
          } else {
            def.reject();
          }
        }, def.reject);
    }
  },

  nextClicked() {
    const col = this.model.collection;
    const mod = this.model;

    const index = col.indexOf(mod);
    const nextMod = col.at(index + 1);

    if (nextMod) {
      this.triggerMethod('layout:swap', 'details', { model: nextMod });
    } else if (col.canFetchPrevious()) {
      const def = this.loader.startLoader();
      const self = this;
      col.fetchPrevious()
        .then(() => {
          const nextMod = col.at(index + 1);
          if (nextMod) {
            self.triggerMethod('layout:swap', 'details', { model: nextMod });
          } else {
            def.reject();
          }
        }, def.reject);
    }
  },

  /**
         * Getting the templateBody from the template before showing it.
         */
  onShow() {
    const el = this.ui.iframe.get(0);
    if (el) {
      // Preparing the view for the data
      const doc = el.contentWindow.document;

      // Writing the doc content.
      doc.open();
      doc.write(this.model.getParsedDataHtml());
      doc.close();
    }
    this.markAsRead();
  },

  markAsRead() {
    const { model } = this;
    if (model.get('is_unread')) {
      model.read()
        .then(() => {
          model.set('is_unread', false);
          NotificationsModel.fetchNotifications();
        });
    }
  },

  canPrevious() {
    const col = this.model.collection;
    const mod = this.model;

    const index = col.indexOf(mod);
    const previousMod = col.at(index - 1);

    if (previousMod) {
      return true;
    }
    return col.canFetchPrevious();
  },

  canNext() {
    const col = this.model.collection;
    const mod = this.model;

    const index = col.indexOf(mod);
    const nextMod = col.at(index + 1);

    if (nextMod) {
      return true;
    }
    return col.canFetchNext();
  },

  serializeData() {
    const data = this.model.toJSON();

    data.canPrevious = this.canPrevious();
    data.canNext = this.canNext();

    return data;
  },

}));
