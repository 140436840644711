define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/orders/date',

  'modules/common/components/locale',
  'modules/common/components/moment',
  'modules/shop.cash-register-retail/components/datepickk',
], (
  $, _, Backbone, Template,
  Locale, Moment, Datepickk,
) => Backbone.Marionette.LayoutView.extend({

  template: Template,

  events: {
    click: 'elementClicked',
  },

  modelEvents: {
    'change:dateFrom': 'dateChanged',
    'change:dateTo': 'dateChanged',
  },

  initialize() {
    this.setDefaultDates();
  },

  setDefaultDates(from, to) {
    if (!from) {
      const d = new Date();
      d.setDate(d.getDate() - 100); // last 100 days
      from = d;
    }
    if (!to) {
      to = new Date();
    }
    this.model.set({
      dateFrom: from,
      dateTo: to,
    });
  },

  elementClicked() {
    const self = this;
    const datepicker = new Datepickk();

    datepicker.lang = Locale.getLocale();
    datepicker.button = Locale.translate('ok');
    datepicker.today = true;
    datepicker.range = true;
    datepicker.maxDate = new Date();
    datepicker.onConfirm = function () {
      const dates = datepicker.selectedDates;
      const from = dates[1] ? dates[1] : null;
      const to = dates[2] ? dates[2] : from;
      self.setDefaultDates(from, to);
    };

    datepicker.selectDate(this.dayShowing);
    datepicker.show();
  },

  dateChanged() {
    !this.isDestroyed ? this.render() : '';
  },

  getDisplayDate() {
    const from = this.model.get('dateFrom');
    const to = this.model.get('dateTo');
    const dateFormat = 'DD-MM-YY';

    // If both are set
    if (from && to && from.toISOString() !== to.toISOString()) {
      return `${Moment(from).format(dateFormat)} / ${Moment(to).format(dateFormat)}`;
    } if (from) {
      return Moment(from).format(dateFormat);
    }
    // if non are set

    return Locale.translate('date_from_till');
  },

  serializeData() {
    return {
      displayDate: this.getDisplayDate(),
    };
  },

}));
